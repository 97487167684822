import { createTheme } from '@mui/material'
import defaultThemePalette from './themePalette'
import localFont from 'next/font/local'

export const MarkProBoldWoff2 = localFont({ src: '../../public/fonts/MarkPro-Bold.woff2', preload: true })
export const MarkProBookWoff2 = localFont({ src: '../../public/fonts/MarkPro-Book.woff2', preload: true })
export const MarkProLightWoff2 = localFont({ src: '../../public/fonts/MarkPro-Light.woff2', preload: true })
export const MarkProMediumWoff2 = localFont({ src: '../../public/fonts/MarkPro-Medium.woff2', preload: true })
export const MarkProRegularWoff2 = localFont({ src: '../../public/fonts/MarkPro-Regular.woff2', preload: true })
export const MarkProThinWoff2 = localFont({ src: '../../public/fonts/MarkPro-Thin.woff2', preload: true })
export const MarkProWoff2 = localFont({ src: '../../public/fonts/MarkPro.woff2', preload: true })

export const GothamBoldWoff2 = localFont({ src: '../../public/fonts/Gotham-Bold.woff2', preload: true })
export const GothamBoldItalicWoff2 = localFont({ src: '../../public/fonts/Gotham-BoldItalic.woff2', preload: true })
export const GothamBookWoff2 = localFont({ src: '../../public/fonts/Gotham-Book.woff2', preload: true })
export const GothamBookItalicWoff2 = localFont({ src: '../../public/fonts/Gotham-BookItalic.woff2', preload: true })
export const GothamLightWoff2 = localFont({ src: '../../public/fonts/Gotham-Light.woff2', preload: true })
export const GothamLightItalicWoff2 = localFont({ src: '../../public/fonts/Gotham-LightItalic.woff2', preload: true })
export const GothamMediumWoff2 = localFont({ src: '../../public/fonts/Gotham-Medium.woff2', preload: true })
export const GothamMediumItalicWoff2 = localFont({ src: '../../public/fonts/Gotham-MediumItalic.woff2', preload: true })

export const AzeretMonoLight = localFont({ src: '../../public/fonts/AzeretMono-Light.ttf', preload: true })
export const AzeretMonoRegular = localFont({ src: '../../public/fonts/AzeretMono-Regular.ttf', preload: true })

declare module '@mui/material/styles' {
   interface Palette {
      charcoalAlpha: string
      charcoal: string
      glassButtonVariantHover: string
      playerSeekbarRail: string
      headerLGColor: string
      leftLinearGradient: string
      radialGradient: string
      playerGradient: string
      playRadialGradient: string
      subtitleCardGradient: string
      subtitleDividerGradient: string
      infoPageAnalytics: string
      cardGradient: string
      bannerGradientTopLeft: string
      bannerGradientBottom: string
      cardOverlayBackground: string
      cardOverlayBackgroundHover: string
      cardBorderColor: string
      genreCardGradient: string
      genreCardGradientHover: string
      trickplayThumbnailGradient: string
      upnextOverlay: string
      tabBorderColor: string
      tabSynopsisTextColor: string
      inActiveTabColor: string
      upnextPicture: string
      seasonEpisodeBorder: string
      episodeStripGradient: string
      playerControlHoverGradient: string
      avatarBackgrund: string
      audioDefaultTrack: string
      audioHighlightedTrack: string
      episodesExtrasGradient: string
      footerGradient: string
      footerDividerGradient: string
      promoSingularRailCardGradient: string
      castCrewTabsBorderBottom: string
      errorTextColor: string
      dropDownBg: string
      scrollbar: string
      scrollbarThumbBackground: string
      scrollbarThumbBorder: string
      scrollbarThumbFocusBackground: string
      scrollbarColor1: string
      scrollbarColor2: string
      dividerGradient: string
      profileListText: string
      productCardBg: string
      overlayText: string
      cwProgressOverlay: string
      cardOverlay: string
      signOutDialogBackground: string
      comingSoonLabel: string
      newLabel: string
      almondSubHeading: string
      ironGradientSubHeading: string
      feedHeroCarouselGradientLeft: string
      feedHeroCarouselGradientBottom: string
      feedHeroCarouselBannerBackground: string
      feedHeroCarouselTimeYearGenreText: string
      railCardInteriorBackground: string
      railSwiperChevronBackground: string
      leavingBannerBadgeBackground: string
      newBannerBadgeBackground: string
      commingSoonBannerBadgeBackground: string
      thumbnailGradient: string
      accountPageModuleGradient: string
      accountPageModuleHeaderGradient: string
      profileModuleButton: string
      depressed: string
      raised: string
      uniform: string
      dropshadow: string
      promoCardGradient: string
      darkSlateGray: string
      dotGradient: string
   }

   interface TypographyVariants {
      h7?: React.CSSProperties
      h8?: React.CSSProperties
      buttonBold?: React.CSSProperties
      caption1?: React.CSSProperties
      caption2?: React.CSSProperties
      caption3?: React.CSSProperties
   }

   // allow configuration using `createTheme`
   interface TypographyVariantsOptions {
      h7?: React.CSSProperties
      h8?: React.CSSProperties
      buttonBold?: React.CSSProperties
      caption1?: React.CSSProperties
      caption2?: React.CSSProperties
      caption3?: React.CSSProperties
   }

   interface PaletteOptions {
      charcoalAlpha: string
      charcoal: string
      glassButtonVariantHover: string
      playerSeekbarRail: string
      headerLGColor: string
      leftLinearGradient: string
      radialGradient: string
      playerGradient: string
      playRadialGradient: string
      subtitleCardGradient: string
      subtitleDividerGradient: string
      infoPageAnalytics: string
      cardGradient: string
      bannerGradientTopLeft: string
      bannerGradientBottom: string
      cardOverlayBackground: string
      cardOverlayBackgroundHover: string
      cardBorderColor: string
      genreCardGradient: string
      genreCardGradientHover: string
      trickplayThumbnailGradient: string
      upnextOverlay: string
      tabBorderColor: string
      tabSynopsisTextColor: string
      inActiveTabColor: string
      upnextPicture: string
      seasonEpisodeBorder: string
      episodeStripGradient: string
      playerControlHoverGradient: string
      avatarBackgrund: string
      audioDefaultTrack: string
      audioHighlightedTrack: string
      episodesExtrasGradient: string
      footerGradient: string
      footerDividerGradient: string
      promoSingularRailCardGradient: string
      castCrewTabsBorderBottom: string
      errorTextColor: string
      dividerGradient: string
      dropDownBg: string
      scrollbar: string
      scrollbarThumbBackground: string
      scrollbarThumbBorder: string
      scrollbarThumbFocusBackground: string
      scrollbarColor1: string
      scrollbarColor2: string
      profileListText: string
      productCardBg: string
      overlayText: string
      cwProgressOverlay: string
      cardOverlay: string
      comingSoonLabel: string
      newLabel: string
      almondSubHeading: string
      ironGradientSubHeading: string
      feedHeroCarouselGradientLeft: string
      feedHeroCarouselGradientBottom: string
      feedHeroCarouselBannerBackground: string
      feedHeroCarouselTimeYearGenreText: string
      railCardInteriorBackground: string
      railSwiperChevronBackground: string
      leavingBannerBadgeBackground: string
      newBannerBadgeBackground: string
      commingSoonBannerBadgeBackground: string
      thumbnailGradient: string
      accountPageModuleGradient: string
      accountPageModuleHeaderGradient: string
      profileModuleButton: string
      depressed: string
      raised: string
      uniform: string
      dropshadow: string
      promoCardGradient: string
      dotGradient: string
   }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
   interface TypographyPropsVariantOverrides {
      h7: true
      h8: true
      buttonBold: true
      caption1: true
      caption2: true
      caption3: true
   }
}

declare module '@mui/material/Button' {
   interface ButtonPropsVariantOverrides {
      outro: true
      glass: true
   }
}

// Create a theme instance.
const theme = createTheme({
   palette: defaultThemePalette,
   typography: {
      h1: {
         fontFamily: MarkProWoff2.style.fontFamily,
         fontSize: '72px',
         fontStyle: 'normal',
         fontWeight: 350,
         lineHeight: 'normal',
         textTransform: 'uppercase',
      },
      h2: {
         fontFamily: MarkProWoff2.style.fontFamily,
         fontSize: '60px',
         fontStyle: 'normal',
         fontWeight: 350,
         lineHeight: '66px',
         letterSpacing: '6px',
         textTransform: 'uppercase',
      },
      h3: {
         fontFamily: MarkProWoff2.style.fontFamily,
         fontSize: '60px',
         fontStyle: 'normal',
         fontWeight: 350,
         lineHeight: '66px',
         textTransform: 'capitalize',
      },
      h4: {
         fontFamily: MarkProWoff2.style.fontFamily,
         fontSize: '40px',
         fontStyle: 'normal',
         fontWeight: 350,
         lineHeight: '48px',
         letterSpacing: '4px',
         textTransform: 'uppercase',
      },
      h5: {
         fontFamily: MarkProWoff2.style.fontFamily,
         fontSize: '34px',
         fontStyle: 'normal',
         fontWeight: 350,
         lineHeight: '37.4px',
         letterSpacing: '3.4px',
         textTransform: 'uppercase',
      },
      h6: {
         fontFamily: MarkProWoff2.style.fontFamily,
         fontSize: '34px',
         fontStyle: 'normal',
         fontWeight: 700,
         lineHeight: '37.4px',
         letterSpacing: '3.4px',
         textTransform: 'uppercase',
      },
      h7: {
         fontFamily: MarkProWoff2.style.fontFamily,
         fontSize: '24px',
         fontStyle: 'normal',
         fontWeight: 450,
         lineHeight: '36px',
         letterSpacing: '2.4px',
         textTransform: 'uppercase',
      },
      h8: {
         fontFamily: MarkProWoff2.style.fontFamily,
         fontSize: '24px',
         fontStyle: 'normal',
         fontWeight: 400,
         lineHeight: '36px',
         letterSpacing: '0.48px',
         textTransform: 'uppercase',
      },
      button: {
         fontFamily: MarkProWoff2.style.fontFamily,
         fontSize: '14px',
         fontStyle: 'normal',
         fontWeight: 400,
         lineHeight: '15.4px',
         letterSpacing: '1.4px',
         textTransform: 'uppercase',
      },
      buttonBold: {
         fontFamily: MarkProWoff2.style.fontFamily,
         fontSize: '14px',
         fontStyle: 'normal',
         fontWeight: 700,
         lineHeight: '15.4px',
         letterSpacing: '1.4px',
         textTransform: 'uppercase',
      },
      caption1: {
         fontFamily: MarkProWoff2.style.fontFamily,
         fontSize: '14px',
         fontStyle: 'normal',
         fontWeight: 400,
         lineHeight: '21px',
      },
      caption2: {
         fontFamily: MarkProWoff2.style.fontFamily,
         fontSize: '12px',
         fontStyle: 'normal',
         fontWeight: 500,
         lineHeight: 'normal',
         textTransform: 'uppercase',
      },
      caption3: {
         fontFamily: MarkProWoff2.style.fontFamily,
         fontSize: '12px',
         fontStyle: 'normal',
         fontWeight: 400,
         lineHeight: 'normal',
      },
      subtitle1: {
         fontFamily: MarkProWoff2.style.fontFamily,
         fontSize: '16px',
         fontStyle: 'normal',
         fontWeight: 700,
         lineHeight: '17.6px',
         letterSpacing: '1.6px',
         textTransform: 'uppercase',
      },
      subtitle2: {
         fontFamily: MarkProWoff2.style.fontFamily,
         fontSize: '16px',
         fontStyle: 'normal',
         fontWeight: 700,
         lineHeight: '17.6px',
         letterSpacing: '0.32px',
      },
      body1: {
         fontFamily: MarkProWoff2.style.fontFamily,
         fontSize: '24px',
         fontStyle: 'normal',
         fontWeight: 400,
         lineHeight: '36px',
         letterSpacing: '0.48px',
      },
      body2: {
         fontFamily: MarkProWoff2.style.fontFamily,
         fontSize: '16px',
         fontStyle: 'normal',
         fontWeight: 400,
         lineHeight: '24px',
         letterSpacing: '0.32px',
      },
      overline: {
         fontFamily: MarkProWoff2.style.fontFamily,
         fontSize: '12px',
         fontStyle: 'normal',
         fontWeight: 500,
         lineHeight: '16px',
         letterSpacing: '0.96px',
         textTransform: 'uppercase',
      },
   },
   components: {
      MuiFormLabel: {
         styleOverrides: {
            asterisk: { color: 'red', display: 'none' },
         },
      },
      MuiIconButton: {
         defaultProps: {
            disableRipple: true,
         },
         variants: [
            {
               props: { color: 'secondary' },
               style: {
                  backgroundColor: defaultThemePalette.charcoalAlpha,
               },
            },
         ],
      },
      MuiButton: {
         defaultProps: {
            disableRipple: true,
            disableElevation: true,
         },
         variants: [
            {
               props: { variant: 'contained' },
               style: {
                  color: defaultThemePalette.secondary?.main,
                  'backgroundColor': defaultThemePalette.charcoalAlpha,
                  '&:hover': {
                     color: defaultThemePalette.secondary?.main,
                     'backgroundColor': defaultThemePalette.primary.main,
                  },
               },
            },
            {
               props: { variant: 'outro' } as any,
               style: {
                  color: defaultThemePalette.common.black,
                  'backgroundColor': defaultThemePalette.secondary?.main,
                  '&:hover': {
                     color: defaultThemePalette.common.black,
                     'backgroundColor': defaultThemePalette.secondary?.main,
                  },
               },
            },
            {
               props: { variant: 'glass' } as any,
               style: {
                  color: defaultThemePalette.common.white,
                  'backgroundColor': defaultThemePalette.charcoal,
                  border: '1px solid ' + defaultThemePalette.common.white,
                  '&:hover': {
                     'backgroundColor': defaultThemePalette.glassButtonVariantHover,
                     border: '1px solid ' + defaultThemePalette.glassButtonVariantHover,
                  },
               },
            },
            {
               props: { variant: 'outlined' },
               style: {
                  'backgroundColor': defaultThemePalette.background.default,
                  '&:hover': {
                     color: defaultThemePalette.background.default,
                     'backgroundColor': defaultThemePalette.secondary?.main,
                     border: `1px solid ${defaultThemePalette.secondary?.main}`,
                  },
               },
            },
         ],
      },
      MuiTab: {
         defaultProps: {
            disableRipple: true,
            disableFocusRipple: true,
         },
      },
      MuiCssBaseline: {
         styleOverrides: {
            body: {
               '& .MuiAccordionDetails-root,  .has-scroll': {
                  scrollbarColor: `${defaultThemePalette.scrollbarColor1} ${defaultThemePalette.scrollbarColor1}`,
                  '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
                     backgroundColor: defaultThemePalette.scrollbar,
                  },
                  '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
                     borderRadius: 50,
                     backgroundColor: defaultThemePalette.scrollbarThumbBackground,
                     minHeight: 24,
                     border: `0.0625rem solid ${defaultThemePalette.scrollbarThumbBorder}`,
                  },
                  '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
                     backgroundColor: defaultThemePalette.scrollbarThumbFocusBackground,
                  },
                  '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
                     backgroundColor: defaultThemePalette.scrollbarThumbFocusBackground,
                  },
                  '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
                     backgroundColor: defaultThemePalette.scrollbarThumbFocusBackground,
                  },
                  '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
                     backgroundColor: defaultThemePalette.scrollbarThumbBorder,
                  },
               },
            },
         },
      },
   },
})

export default theme
