const colorPalette = {
   primary: {
      light: '#7673ff',
      main: '#5450FF',
      dark: '#3a38b2',
   },
   secondary: {
      light: '#e1dfd7',
      main: '#DAD7CD',
      dark: '#98968f',
   },
   background: {
      default: '#0E141C',
   },
   text: {
      primary: '#DAD7CD',
      secondary: '#ffffff',
   },
   common: {
      white: '#ffffff',
      black: '#000000',
   },
   charcoalAlpha: '#DAD7CD33',
   charcoal: '#0E141C',
   darkSlateGray: '#454955',
   glassButtonVariantHover: '#333333',
   castCrewTabsBorderBottom: '#BAB6A9',
   playerSeekbarRail: 'rgba(218, 215, 205, 0.5)',
   headerLGColor: 'linear-gradient(136deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.04) 100%)',
   leftLinearGradient:
      'linear-gradient(90deg, #0E141C 0%, #0E141C 30.12%, rgba(14, 20, 28, 0.9) 42.18%, rgba(14, 20, 28, 0.5) 57.4%, rgba(14, 20, 28, 0) 100%)',
   radialGradient: 'radial-gradient(88.42% 68.64% at 75.9% 17.93%, rgba(14, 20, 28, 0) 0%, #0E141C 100%)',
   playerGradient: 'linear-gradient(180deg, rgba(0, 0, 0, 0.49) 0%, rgba(0, 0, 0, 0.98) 100%)',
   playRadialGradient: 'linear-gradient(305.18deg, #2A25FF 8.7%, #7C77FF 88.05%)',
   subtitleCardGradient: 'linear-gradient(136.12deg, #3D4249 0%, #181D25 76.15%)',
   subtitleDividerGradient: 'linear-gradient(90deg, rgba(218, 215, 205, 0) 0%, rgba(218, 215, 205, 0.2) 100%)',
   infoPageAnalytics: '#939393',
   cardGradient: 'linear-gradient(136.12deg, rgba(41, 48, 55, 1) 0%, rgba(19, 26, 33, 0.038) 76.15%)',
   bannerGradientTopLeft:
      'linear-gradient(90deg, #0E141C 43.52%, rgba(14, 20, 28, 0.81) 61.71%, rgba(14, 20, 28, 0) 100%);',
   bannerGradientBottom: 'linear-gradient(0deg, #0E141C 0%, #0E141C 46.42%, rgba(14, 20, 28, 0) 100%);',
   cardOverlayBackground: 'rgba(0, 0, 0, 0.3)',
   cardOverlayBackgroundHover: 'rgba(0,0,0,0)',
   cardBorderColor: '#5450ff',
   genreCardGradient: 'linear-gradient(136.12deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.038) 76.15%)',
   genreCardGradientHover: 'linear-gradient(135deg, #706DFF -0.33%, #5C58FF 100.33%);',
   trickplayThumbnailGradient: 'radial-gradient(70.32% 221.84% at 29.68% 30.35%, #FFFFFF 0%, #000000 100%)',
   upnextOverlay: 'linear-gradient(136.12deg, #3D4249 0%, #181D25 76.15%)',
   tabBorderColor: '#bab6a9',
   tabSynopsisTextColor: 'rgba(255, 255, 255, 0.89)',
   inActiveTabColor: 'rgba(255, 255, 255, 0.4)',
   upnextPicture: 'radial-gradient(70.32% 221.84% at 29.68% 30.35%, #FFFFFF 0%, #000000 100%)',
   seasonEpisodeBorder:
      'linear-gradient(90deg, rgba(218, 215, 205, 0) 0%, rgba(218, 215, 205, 0.2) 13.02%, rgba(218, 215, 205, 0.2) 85.94%, rgba(218, 215, 205, 0) 100%)',
   episodeStripGradient: 'linear-gradient(136.12deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.04) 76.15%)',
   playerControlHoverGradient: 'linear-gradient(136deg,rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.04) 100% );',
   avatarBackgrund: 'linear-gradient(136deg,rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.04) 100% )',
   audioDefaultTrack: '#FFFFFF80',
   audioHighlightedTrack: 'linear-gradient(305.18deg, #2A25FF 8.7%, #7C77FF 88.05%)',
   episodesExtrasGradient: 'linear-gradient(136deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.04) 76.15%)',
   footerGradient: 'linear-gradient(136deg, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0.01) 76.15%)',
   footerDividerGradient: 'linear-gradient(270deg, rgba(218, 215, 205, 0.00) 0%, rgba(218, 215, 205, 0.20) 100%)',
   promoSingularRailCardGradient: 'radial-gradient(147.47% 39.82% at 32.02% 45.58%, rgba(0, 0, 0, 0.00) 0%, #000 100%)',
   errorTextColor: '#e09575',
   dividerGradient: 'linear-gradient(240deg,  rgba(218, 215, 205, 0) 0%, rgba(218, 215, 205, 0.2) 100%)',
   dropDownBg: 'rgba(14, 20, 28, 1)',
   scrollbar: 'rgba(14, 20, 28, 0.5)',
   scrollbarThumbBackground: '#1f252c',
   scrollbarThumbBorder: '#2b2b2b',
   scrollbarThumbFocusBackground: '#959595',
   scrollbarColor1: '#6b6b6b',
   scrollbarColor2: '#2b2b2b',
   profileListText: 'rgb(159, 162, 165)',
   productCardBg:
      'linear-gradient(0deg, rgba(218, 215, 205, 0.1), rgba(218, 215, 205, 0.1)), linear-gradient(136.12deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.038) 76.15%)',
   overlayText: 'rgba(255, 255, 255, 0.6)',
   cwProgressOverlay:
      'linear-gradient( 180.08deg, rgba(0, 0, 0, 0) 54.73%, rgba(0, 0, 0, 0.37) 69.68%, #000000 100.13%)',
   cardOverlay: 'rgba(0, 0, 0, 0.3)',
   signOutDialogBackground: 'linear-gradient(136deg, rgb(61, 66, 73) 0%, rgb(23, 29, 37) 100%)',
   comingSoonLabel: 'rgb(237, 173, 69)',
   newLabel: '#5450FF',
   almondSubHeading: 'rgba(255,255,255,0.6)',
   ironGradientSubHeading: 'linear-gradient(180deg, rgba(218, 215, 205, 0.60) 24.49%, rgba(218, 215, 205, 0.40) 80%)',
   feedHeroCarouselGradientLeft: 'linear-gradient(90deg, #0E141C 55.87%, rgba(14, 20, 28, 0) 100%)',
   feedHeroCarouselGradientBottom: 'linear-gradient(0deg, #0E141C 0%, #0E141C 20.58%, rgba(14, 20, 28, 0) 32.15%)',
   feedHeroCarouselTimeYearGenreText: 'rgba(218, 215, 205, 1)',
   feedHeroCarouselBannerBackground: '#0F151D',
   railCardInteriorBackground:
      'linear-gradient(136deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.04) 76.15%)',
   railSwiperChevronBackground: 'linear-gradient(136.12deg, #25292D 0%, #1E2124 76.15%)',
   leavingBannerBadgeBackground: `linear-gradient(136.12deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.038) 76.15%),
   linear-gradient(0deg, #54329C, #54329C)`,
   newBannerBadgeBackground: `linear-gradient(136.12deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.038) 76.15%),
   linear-gradient(0deg, #404580, #404580)`,
   commingSoonBannerBadgeBackground: `linear-gradient(0deg, #C08C39, #C08C39),
   linear-gradient(136.12deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.038) 76.15%)`,
   thumbnailGradient: 'linear-gradient(155.94deg, #3D4148 -0.02%, #1C2129 99.93%)',
   promoCardGradient: '#363B48',
   accountPageModuleGradient:
      'linear-gradient(136.12deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.038) 76.15%)',
   accountPageModuleHeaderGradient: 'linear-gradient(270deg, rgba(218, 215, 205, 0) 0%, rgba(218, 215, 205, 0.2) 100%)',
   profileModuleButton: '#FFFFFF1A',

   depressed: `rgb(204, 204, 204) 1px 1px, rgb(204, 204, 204) 0px 1px, rgb(34, 34, 34) -1px -1px, rgb(34, 34, 34) 0px -1px`,
   raised: `rgb(34, 34, 34) 1px 1px, rgb(34, 34, 34) 2px 2px, rgb(34, 34, 34) 3px 3px`,
   uniform: `rgb(34, 34, 34) 0px 0px 4px, rgb(34, 34, 34) 0px 0px 4px, rgb(34, 34, 34) 0px 0px 4px, rgb(34, 34, 34) 0px 0px 4px`,
   dropshadow: `rgb(34, 34, 34) 2px 2px 3px, rgb(34, 34, 34) 2px 2px 4px, rgb(34, 34, 34) 2px 2px 5px`,
   dotGradient: 'linear-gradient(136.12deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.038) 76.15%)',
}

export default colorPalette
